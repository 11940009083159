import React, { useEffect, useState } from "react";
import cx from "classnames";

import ProductButton from "../ProductButton";
import Backdrop from "../Backdrop";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import { IProduct, ICart } from "../../interface/products";
import { formatRp } from "../../utils/currency";
import styles from "./index.module.css";

interface IProps {
  product: IProduct;
  cart: ICart;
  setCart: (cart: ICart) => void;
  hidePopup: () => void;
  show: boolean;
}

const ProductPopup: React.FC<IProps> = ({
  show,
  product,
  cart,
  setCart,
  hidePopup,
}) => {
  const { id, image, description, name, excerpt, price } = product;
  const [copyCart, setCopyCart] = useState<ICart>({
    storeId: "",
    token: "",
    totalItems: 0,
    totalPrice: 0,
    items: {},
  });

  const actionMergeCart = () => {
    const mergeCartItems = {
      ...cart.items,
      ...copyCart.items,
    };

    for (let key in mergeCartItems) {
      if (mergeCartItems[key] === 0) {
        delete mergeCartItems[key];
      }
    }

    const mergeCart: ICart = {
      ...cart,
      totalItems: copyCart.totalItems,
      totalPrice: copyCart.totalPrice,
      items: mergeCartItems,
    };
    setCart(mergeCart);
  };

  useEffect(() => {
    if (show) {
      setCopyCart(JSON.parse(JSON.stringify(cart)));
      if (!cart.items[id]) {
        setCopyCart({
          ...cart,
          totalItems: cart.totalItems + 1,
          totalPrice: cart.totalPrice + price,
          items: { ...cart.items, [id]: 1 },
        });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [show]);

  const renderButton = () => {
    if (!cart.items[id] && copyCart.items[id] > 0) {
      return (
        <button className={styles.addButton} onClick={actionMergeCart}>
          +Tambah
        </button>
      );
    }
    if (cart.items[id] > 0 && copyCart.items[id]) {
      return (
        <button className={styles.addButton} onClick={actionMergeCart}>
          Update
        </button>
      );
    }
    if (cart.items[id] > 0 && copyCart.items[id] === 0) {
      return (
        <button
          className={[styles.addButton, styles.delete].join(" ")}
          onClick={actionMergeCart}
        >
          Hapus
        </button>
      );
    }
    if (!cart.items[id] && copyCart.items[id] === 0) {
      return (
        <button className={styles.addButton} onClick={hidePopup}>
          Kembali
        </button>
      );
    }
  };

  return (
    <>
      <Backdrop show={show} onClick={hidePopup} />
      <div className={cx(styles.container, { [styles.hide]: !show })}>
        <div className={styles.image}>
          <img src={image ? image["480x360"] : ""} alt="" />
          <CloseIcon className={styles.close} onClick={hidePopup} />
        </div>
        <div className={styles.nameAndPrice}>
          <h3 className={styles.name}>{name}</h3>
          <span className={styles.price}>{formatRp(price)}</span>
        </div>
        <p className={styles.excerpt}>{excerpt}</p>
        <p className={styles.description}>
          {description &&
            description.split("\n").map((item, i) => {
              return <p key={i}>{item}</p>;
            })}
        </p>
        <div className={styles.buttonContainer} onClick={hidePopup}>
          <ProductButton
            id={id}
            price={price}
            cart={copyCart}
            setCart={setCopyCart}
            untilZero
          />
          {renderButton()}
        </div>
      </div>
    </>
  );
};

export default ProductPopup;
