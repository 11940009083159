import React from "react";
import cx from "classnames";

import { ReactComponent as DeleteIcon } from "../../assets/icons/trash.svg";
import { ICart } from "../../interface/products";
import styles from "./index.module.css";

interface IProps {
  id: string;
  price: number;
  cart: ICart;
  setCart: (cart: ICart) => void;
  untilZero?: boolean;
}

const ProductButton: React.FC<IProps> = ({
  id,
  price,
  cart,
  setCart,
  untilZero = false,
}) => {
  const addProduct = () => {
    if (cart.items[id]) {
      setCart({
        ...cart,
        totalItems: cart.totalItems + 1,
        totalPrice: cart.totalPrice + price,
        items: { ...cart.items, [id]: cart.items[id] + 1 },
      });
    } else {
      setCart({
        ...cart,
        totalItems: cart.totalItems + 1,
        totalPrice: cart.totalPrice + price,
        items: { ...cart.items, [id]: 1 },
      });
    }
  };

  const subProduct = () => {
    if (cart.items[id] > 1) {
      setCart({
        ...cart,
        totalItems: cart.totalItems - 1,
        totalPrice: cart.totalPrice - price,
        items: { ...cart.items, [id]: cart.items[id] - 1 },
      });
    } else {
      removeProduct();
    }
  };

  const removeProduct = () => {
    if (untilZero) {
      setCart({
        ...cart,
        totalItems: cart.totalItems - cart.items[id],
        totalPrice: cart.totalPrice - cart.items[id] * price,
        items: { ...cart.items, [id]: 0 },
      });
    } else {
      const copyCartItems = { ...cart.items };
      delete copyCartItems[id];
      setCart({
        ...cart,
        totalItems: cart.totalItems - cart.items[id],
        totalPrice: cart.totalPrice - cart.items[id] * price,
        items: { ...copyCartItems },
      });
    }
  };

  if (cart.items[id] === undefined) {
    const addNewItem = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();
      addProduct();
    };

    return (
      <button className={styles.addButton} onClick={addNewItem}>
        + Tambah
      </button>
    );
  }

  return (
    <div
      className={styles.quantityButtonContainer}
      onClick={(event) => event.stopPropagation()}
    >
      <DeleteIcon
        className={cx(styles.deleteIcon, {
          [styles.iconDisabled]: untilZero && cart.items[id] === 0,
        })}
        onClick={removeProduct}
      />
      <div className={styles.quantityButton}>
        <button
          className={styles.decrement}
          onClick={subProduct}
          disabled={untilZero && cart.items[id] === 0}
        >
          -
        </button>
        {cart.items[id]}
        <button className={styles.increment} onClick={addProduct}>
          +
        </button>
      </div>
    </div>
  );
};

export default ProductButton;
