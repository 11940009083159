import React from "react";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import styles from "./index.module.css";

function Footer() {
  function actionFocusToSearch() {
    document.getElementById("search")?.focus();
  }

  return (
    <footer className={styles.container} onClick={actionFocusToSearch}>
      <SearchIcon className={styles.icon} />
      <div className={styles.text}>
        <h5 className={styles.title}>Belum ketemu barang yang dicari?</h5>
        <p className={styles.subtitle}>Klik disini untuk mencari</p>
      </div>
    </footer>
  );
}

export default Footer;
