interface IConfigParams {
  prefix?: boolean;
  sign?: boolean;
  separator?: string;
}

// workaround, since I not found proper library to do this
// not support value with comma, I think we don't need that (yet) too fortunately

export const formatRp = (
  number: number,
  { prefix, sign, separator = "." }: IConfigParams = {}
): string => {
  let formattedNumber = "";

  let formattedNumberWithSeparator = number.toString().split("").reverse();
  for (let index = 0; index < formattedNumberWithSeparator.length; index++) {
    if (index !== 0 && index % 3 === 0) {
      formattedNumber = separator + formattedNumber;
    }
    formattedNumber = formattedNumberWithSeparator[index] + formattedNumber;
  }

  if (prefix) formattedNumber = "Rp" + formattedNumber;

  if (sign) {
    if (number >= 0) formattedNumber = "+" + formattedNumber;
    else formattedNumber = "-" + formattedNumber;
  }

  return formattedNumber;
};
