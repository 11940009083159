import React from "react";
import cx from "classnames";

import ProductButton from "../ProductButton";
import { ICart, IProduct } from "../../interface/products";
import { formatRp } from "../../utils/currency";
import styles from "./index.module.css";

interface IProps {
  product: IProduct;
  cart: ICart;
  setCart: (cart: ICart) => void;
}

const SimpleProduct: React.FC<IProps> = ({ product, cart, setCart }) => {
  const { id, name, excerpt, price, availability } = product;

  return (
    <>
      <div
        className={cx(styles.container, { [styles.disabled]: !availability })}
      >
        <div className={styles.leftSide}>
          <h3 className={styles.name}>{name}</h3>
          <div className={styles.description}>
            <p className={styles.id}>{id}</p>
            <p className={styles.excerpt}>{excerpt}</p>
          </div>
        </div>
        <div className={styles.rightSide}>
          <h4 className={styles.price}>{formatRp(price)}</h4>
          {availability ? (
            <ProductButton
              id={id}
              price={price}
              cart={cart}
              setCart={setCart}
            />
          ) : (
            <span className={styles.empty}>Kosong</span>
          )}
        </div>
      </div>
      <hr className={styles.divider} />
    </>
  );
};

export default SimpleProduct;
