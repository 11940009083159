import React from "react";

import Product from "../Product";
import SimpleProduct from "../SimpleProduct";
import { IProducts, ICart } from "../../interface/products";
import styles from "./index.module.css";

interface IProps {
  products: IProducts[];
  cart: ICart;
  setCart: (cart: ICart) => void;
  bulk?: boolean;
}

const ProductGroup: React.FC<IProps> = ({
  products,
  cart,
  setCart,
  bulk = false,
}) => {
  const ProductComponent = bulk ? SimpleProduct : Product;

  return (
    <>
      {products.map((product) => (
        <div
          className={styles.container}
          key={product.id}
          id={product.category}
        >
          <h2 className={styles.title}>{product.category}</h2>
          {product.items.map((item) => (
            <ProductComponent
              key={item.id}
              product={item}
              cart={cart}
              setCart={setCart}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default ProductGroup;
