import React, { useState } from "react";
import cx from "classnames";
import { CSSTransition } from "react-transition-group";

import Product from "../Product";
import CheckoutButton from "../CheckoutButton";
import Backdrop from "../Backdrop";
import RedirectPopup from "../RedirectPopup";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import { ICart, IProduct } from "../../interface/products";
import { formatRp } from "../../utils/currency";
import styles from "./index.module.css";
import SimpleProduct from "../SimpleProduct";

interface IProps {
  cartItems: IProduct[];
  cart: ICart;
  setCart: (cart: ICart) => void;
  show: boolean;
  hidePopup: () => void;
  bulk?: boolean;
}

const CheckoutPopup: React.FC<IProps> = ({
  cartItems,
  cart,
  setCart,
  show,
  hidePopup,
  bulk = false,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const displayLoader = () => setShowLoader(true);

  return (
    <>
      <Backdrop show={show} onClick={hidePopup} />
      <CSSTransition
        in={show}
        timeout={500}
        classNames={{
          enter: styles["popup-enter"],
          enterActive: styles["popup-enter-active"],
          exit: styles["popup-exit"],
          exitActive: styles["popup-exit-active"],
        }}
        unmountOnExit
      >
        <div className={cx(styles.wrapper)}>
          <div className={styles.container}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>Konfirmasi Pesanan</h2>
              <CloseIcon onClick={hidePopup} className={styles.close} />
            </div>
            <hr className={styles.divider} />
            {cartItems.map((item) =>
              bulk ? (
                <SimpleProduct
                  key={item.id}
                  product={item}
                  cart={cart}
                  setCart={setCart}
                />
              ) : (
                <Product
                  key={item.id}
                  product={item}
                  cart={cart}
                  setCart={setCart}
                  popup={false}
                />
              )
            )}
            <button className={styles.addButton} onClick={hidePopup}>
              + Tambah Barang
            </button>
            <h3 className={styles.totalPrice}>
              Total : {formatRp(cart.totalPrice)}
            </h3>
            <CheckoutButton onClick={displayLoader} icon={SendIcon}>
              Kirim Checkout Saya
            </CheckoutButton>
          </div>
        </div>
      </CSSTransition>
      <RedirectPopup show={showLoader} cart={cart} />
    </>
  );

  // if (!show) return null;
  // return (
  //   <>
  //     <Backdrop show onClick={hidePopup} />
  //     <div className={cx(styles.wrapper)}>
  //       <div className={styles.container}>
  //         <div className={styles.titleContainer}>
  //           <h2 className={styles.title}>Konfirmasi Pesanan</h2>
  //           <CloseIcon onClick={hidePopup} className={styles.close} />
  //         </div>
  //         <hr className={styles.divider} />
  //         {cartItems.map((item) =>
  //           bulk ? (
  //             <SimpleProduct
  //               key={item.id}
  //               product={item}
  //               cart={cart}
  //               setCart={setCart}
  //             />
  //           ) : (
  //             <Product
  //               key={item.id}
  //               product={item}
  //               cart={cart}
  //               setCart={setCart}
  //               popup={false}
  //             />
  //           )
  //         )}
  //         <button className={styles.addButton} onClick={hidePopup}>
  //           + Tambah Barang
  //         </button>
  //         <h3 className={styles.totalPrice}>
  //           Total : {formatRp(cart.totalPrice)}
  //         </h3>
  //         <CheckoutButton onClick={displayLoader} icon={SendIcon}>
  //           Kirim Checkout Saya
  //         </CheckoutButton>
  //       </div>
  //     </div>
  //     <RedirectPopup show={showLoader} cart={cart} />
  //   </>
  // );
};

export default CheckoutPopup;
