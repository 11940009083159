import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Store from "./pages/store/basic";
import BulkStore from "./pages/store/bulk";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/store/basic/:storeId" component={Store} />
          <Route path="/store/bulk/:storeId" component={BulkStore} />
          {/* Shortened url */}
          <Route exact path="/:storeId" component={Store} />
          <Route exact path="/b/:storeId" component={BulkStore} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
