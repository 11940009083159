import React, { useEffect, useState, useCallback } from "react";
import { SpinnerCircular } from "spinners-react";
import cx from "classnames";
import dayjs from "dayjs";

import { ReactComponent as RedirectIcon } from "../../assets/icons/redirect.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/done.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

import { checkoutAPI } from "../../api/product";
import { ICart } from "../../interface/products";
import styles from "./index.module.css";

interface IProps {
  show: boolean;
  cart: ICart;
}

const RedirectPopup: React.FC<IProps> = ({ show, cart }) => {
  const [success, setSuccess] = useState<null | boolean>(null);
  const [errorDescription, setErrorDescription] = useState(
    "Tidak biasanya kami mengalami ini. Jangan khawatir, kamu bisa coba lagi lain waktu ya."
  );
  const [reportUrl, setReportUrl] = useState(
    `https://api.whatsapp.com/send?phone=6289522028530&text=Halo,%20saya%20ingin%20melaporkan%20adanya%20kesalahan%20saat%20melakukan%20checkout%20di%20aplikasi%20${
      cart.storeId
    }%20pada%20waktu%20${dayjs().format("DD-MM-YYYY HH:mm:ss")}%20WIB`
  );
  const [redirectURL, setRedirectURL] = useState("");

  const checkoutProduct = useCallback(async () => {
    const adjustedCart = Object.keys(cart.items).map((id) => ({
      id,
      quantity: cart.items[id],
    }));

    const cartWithToken = { token: cart.token, cart: adjustedCart };

    try {
      const result = await checkoutAPI(cart.storeId, cartWithToken);

      if (result.data.status === "success") {
        setSuccess(true);

        const url = result.data.result.redirect;
        setRedirectURL(url);
        setTimeout(() => window.location.replace(url), 1000);
      }
    } catch (error) {
      setSuccess(false);

      if (error.response?.data?.message)
        setErrorDescription(error.response.data.message);
      if (error.response?.data?.report_url)
        setReportUrl(error.response.data.report_url);
    }
  }, [cart]);

  useEffect(() => {
    if (show) checkoutProduct();
  }, [show, cart, checkoutProduct]);

  const renderSpinner = () => {
    if (success === null) {
      return (
        <SpinnerCircular
          color="#fff"
          size={200}
          speed={99}
          thickness={139}
          className={styles.doneIcon}
        />
      );
    }
    if (success === true) return <CheckIcon className={styles.doneIcon} />;
    if (success === false) return <CrossIcon className={styles.doneIcon} />;
  };

  const renderIcon = () => {
    if (success === true) return <RedirectIcon className={styles.bodyIcon} />;
    if (success === false) return <InfoIcon className={styles.bodyIcon} />;
  };

  const renderText = () => {
    if (success === null) {
      return (
        <div>
          <h1 className={styles.title}>Pesanan Diproses</h1>
          <p className={styles.description}>
            Checkout daftar belanjaan kamu sedang dialihkan dan dikirim ke
            WhatsApp kami
          </p>
          <p className={styles.description}>Mohon tunggu sebentar...</p>
        </div>
      );
    }
    if (success === false) {
      return (
        <div>
          <h1 className={styles.title}>Hmm Ada yang salah...</h1>
          <p className={styles.description}>{errorDescription}</p>
          <p className={styles.errorMessage}>
            <a
              href={reportUrl}
              className={styles.button}
              target="_blank"
              rel="noopener noreferrer"
            >
              Laporkan kesalahan ini
            </a>
          </p>
        </div>
      );
    }
    if (success === true) {
      return (
        <div>
          <h1 className={styles.title}>Berhasil</h1>
          <p className={styles.description}>Sedang mengalihkan Anda...</p>
          <button
            className={styles.button}
            onClick={() => window.location.replace(redirectURL)}
          >
            Tekan disini jika masih belum teralihkan
          </button>
        </div>
      );
    }
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.error]: success === false,
        [styles.hide]: !show,
      })}
    >
      {renderSpinner()}
      <div className={styles.body}>
        {renderIcon()}
        {renderText()}
      </div>
      {success === false && (
        <button
          className={styles.tryButton}
          onClick={() => {
            setSuccess(null);
            checkoutProduct();
          }}
        >
          Coba Lagi
        </button>
      )}
    </div>
  );
};

export default RedirectPopup;
