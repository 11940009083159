import React from "react";

import styles from "./index.module.css";

interface IProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
}

const CheckoutButton: React.FC<IProps> = ({
  icon: Icon,
  children,
  onClick,
}) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <Icon className={styles.icon} />
      {children}
    </button>
  );
};

export default CheckoutButton;
