import React from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./index.module.css";

interface IProps {
  show: boolean;
  onClick: () => void;
}

const Backdrop: React.FC<IProps> = ({ show, onClick }) => {
  return (
    <CSSTransition
      in={show}
      timeout={500}
      classNames={{
        enter: styles["backdrop-enter"],
        enterActive: styles["backdrop-enter-active"],
        exit: styles["backdrop-exit"],
        exitActive: styles["backdrop-exit-active"],
      }}
      unmountOnExit
    >
      <div className={styles.container} onClick={onClick} />
    </CSSTransition>
  );
};

export default Backdrop;
