import axios from "axios";

const productAPI = axios.create({
  baseURL: "https://13910nm8gl.execute-api.ap-southeast-1.amazonaws.com",
});

export const getProductAPI = async (storeId: string, token: string) => {
  return await productAPI.get(`prod/store/${storeId}/products`, {
    params: {
      token,
    },
  });
};

interface IPostCart {
  token: string;
  cart: { id: string; quantity: number }[];
}

export const checkoutAPI = async (storeId: string, cart: IPostCart) => {
  const result = await productAPI.post(`prod/store/${storeId}/checkout`, cart);

  return result;
};
