import React from "react";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import styles from "./index.module.css";
import CategoryDropdown from "../CategoryDropdown";

interface IProps {
  search: string;
  setSearch: (search: string) => void;
  actionSearch: (event: React.FormEvent<HTMLFormElement>) => void;
  categories?: string[];
}

const SearchBar: React.FC<IProps> = ({
  search,
  setSearch,
  actionSearch,
  categories,
}) => {
  return (
    <form className={styles.container} onSubmit={actionSearch}>
      <label htmlFor="search" className={styles.search}>
        <input
          type="text"
          placeholder="Search"
          className={styles.input}
          id="search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <button className={styles.searchButton}>
          <SearchIcon />
        </button>
      </label>
      {categories && <CategoryDropdown categories={categories} />}
    </form>
  );
};

export default SearchBar;
