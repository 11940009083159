import React, { useState, useEffect } from "react";
import { RouteChildrenProps, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import SearchBar from "../../../components/SearchBar";
import SearchHint from "../../../components/SearchHint";
import ProductGroup from "../../../components/ProductGroup";
import CartSummary from "../../../components/CartSummary";
import { SkeletonViewBasic } from "../../../components/SkeletonView";
import StoreHeader from "../../../components/StoreHeader";

import { IProducts, ICart, ISearchProduct } from "../../../interface/products";
import { getProductAPI } from "../../../api/product";
import { useQuery } from "../../../hooks/useQuery";
import styles from "./index.module.css";

const Store: React.FC<RouteChildrenProps<{ storeId: string }>> = () => {
  const { storeId } = useParams();
  const token = useQuery().get("token") || "";

  const [storeName, setStoreName] = useState("");
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<IProducts[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<ISearchProduct>({
    query: "",
    total: 0,
    products: [],
  });
  const [categories, setCategories] = useState<string[]>([]);
  const [cart, setCart] = useState<ICart>({
    storeId,
    token,
    totalItems: 0,
    totalPrice: 0,
    items: {},
  });

  useEffect(() => {
    const getProducts = async () => {
      try {
        const { data } = await getProductAPI(storeId, token);
        setStoreName(data.store_name);
        setProducts(data.result);
        setSearchedProducts({
          query: "",
          total: 0,
          products: data.result,
        });
      } catch (e) {
        setError(e.response.data.message);
      }
    };

    getProducts();
  }, [storeId, token]);

  useEffect(() => {
    setCategories(searchedProducts.products.map((product) => product.category));
  }, [searchedProducts]);

  const actionSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const matchedProducts: IProducts[] = [];
    let totalProducts = 0;
    for (const product of products) {
      const items = product.items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );

      totalProducts += items.length;
      if (items.length > 0) matchedProducts.push({ ...product, items });
    }

    setSearchedProducts({
      query: search,
      total: totalProducts,
      products: matchedProducts,
    });
  };

  // if (!storeId || !token) return <p className={styles.error}>Not valid URL</p>;
  if (!storeId) return <p className={styles.error}>Not valid URL</p>;
  return (
    <div className={styles.container}>
      <Helmet>
        <title>{storeName ? `ChatNShop - ${storeName}` : "ChatNShop"}</title>
      </Helmet>
      <SearchBar
        search={search}
        setSearch={setSearch}
        actionSearch={actionSearch}
        categories={categories}
      />
      <StoreHeader
        query={searchedProducts.query}
        total={searchedProducts.total}
        storeName={storeName}
        error={error}
      />
      {searchedProducts.products.length === 0 && !error && (
        <SkeletonViewBasic />
      )}
      <ProductGroup
        products={searchedProducts.products}
        cart={cart}
        setCart={setCart}
      />
      {error && <p className={styles.error}>{error}</p>}
      {!(searchedProducts.products.length === 0 && !error) && <SearchHint />}
      <CartSummary cart={cart} setCart={setCart} products={products} />
    </div>
  );
};

export default Store;
