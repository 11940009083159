import React, { useState } from "react";
import cx from "classnames";

import { ReactComponent as CategoryIcon } from "../../assets/icons/category.svg";
import styles from "./index.module.css";

interface IProps {
  categories: string[];
}

const CategoryDropdown: React.FC<IProps> = ({ categories }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.categoryWrapper}>
      <button className={styles.categoryButton} onClick={() => setOpen(!open)}>
        <CategoryIcon /> Lihat Kategori
      </button>
      <div
        className={cx(styles.categoryDropdown, {
          [styles.categoryDropdownHide]: !open,
        })}
      >
        {categories.map((category, index) => (
          <React.Fragment key={category}>
            <p
              className={styles.categoryItem}
              onClick={() => {
                document.getElementById(`${category}`)?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
                setOpen(false);
              }}
            >
              {category}
            </p>
            {index + 1 < categories.length && <hr className={styles.divider} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CategoryDropdown;
