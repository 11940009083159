import React from "react";
import ContentLoader from "react-content-loader";

import { ReactComponent as BrandingLogo } from "../../assets/brand.svg";
import styles from "./index.module.css";

interface IProps {
  total: number;
  query: string;
  storeName: string;
  error: string;
}

const StoreHeader: React.FC<IProps> = ({ total, query, storeName, error }) => {
  const renderHeader = () => {
    if (!storeName && !error) {
      return (
        <ContentLoader speed={0.5} height={29}>
          <rect x="0" y="0" rx="10" ry="10" width="75%" height="29" />
        </ContentLoader>
      );
    }
    if (query) {
      return (
        <p className={styles.result}>
          <span>{total}</span> hasil <span>"{query}"</span>
        </p>
      );
    }
    return <h1 className={styles.title}>{storeName}</h1>;
  };

  return (
    <div className={styles.header}>
      {renderHeader()}
      <BrandingLogo />
    </div>
  );
};

export default StoreHeader;
