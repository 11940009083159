import React, { useState } from "react";
import cx from "classnames";

import CheckoutButton from "../CheckoutButton";
import CheckoutPopup from "../CheckoutPopup";
import { ReactComponent as CartIcon } from "../../assets/icons/cart.svg";

import { formatRp } from "../../utils/currency";
import { ICart, IProducts, IProduct } from "../../interface/products";
import styles from "./index.module.css";

interface IProps {
  products: IProducts[];
  cart: ICart;
  setCart: (cart: ICart) => void;
  bulk?: boolean;
}

const CartSummary: React.FC<IProps> = ({
  products,
  cart,
  setCart,
  bulk = false,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const displayPopup = () => setShowPopup(true);
  const hidePopup = () => setShowPopup(false);

  const getCartItems = () => {
    const matchedItems: IProduct[] = [];
    for (const product of products) {
      const items = product.items.filter((item) => cart.items[item.id]);
      matchedItems.push(...items);
    }
    return matchedItems;
  };

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.hideContainer]:
            cart.totalItems === 0 || cart.totalPrice === 0,
        })}
      ></div>
      <div
        className={cx(styles.floating, {
          [styles.hideButton]: cart.totalItems === 0 || cart.totalPrice === 0,
        })}
        onClick={displayPopup}
      >
        <CheckoutButton icon={CartIcon}>
          <span className={styles.totalItem}>{cart.totalItems} item</span>
          <div className={styles.verticalDivider}></div>
          <span className={styles.totalPrice}>
            {formatRp(cart.totalPrice, { prefix: true })}
          </span>
        </CheckoutButton>
      </div>
      <CheckoutPopup
        cart={cart}
        setCart={setCart}
        cartItems={getCartItems()}
        show={showPopup && (cart.totalItems !== 0 || cart.totalPrice !== 0)}
        hidePopup={hidePopup}
        bulk
      />
    </>
  );
};

export default CartSummary;
