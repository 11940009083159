import React, { useState } from "react";
import cx from "classnames";

import ProductButton from "../ProductButton";
import ProductPopup from "../ProductPopup";

import { ICart, IProduct } from "../../interface/products";
import { formatRp } from "../../utils/currency";
import styles from "./index.module.css";

interface IProps {
  product: IProduct;
  cart: ICart;
  setCart: (cart: ICart) => void;
  popup?: boolean;
}

const Product: React.FC<IProps> = ({
  product,
  cart,
  setCart,
  popup = true,
}) => {
  const { id, name, excerpt, price, image, availability } = product;

  const [showPopup, setShowPopup] = useState(false);
  const displayPopup = () => setShowPopup(true);
  const hidePopup = () => setShowPopup(false);

  return (
    <>
      <ProductPopup
        show={showPopup && popup}
        cart={cart}
        setCart={setCart}
        product={product}
        hidePopup={hidePopup}
      />
      <div
        className={cx(styles.container, { [styles.disabled]: !availability })}
        onClick={displayPopup}
      >
        <div className={styles.image}>
          <img src={image ? image["50x50"] : ""} alt="" />
        </div>
        <div className={styles.info}>
          <h3 className={styles.name}>{name}</h3>
          <p className={styles.excerpt}>{excerpt}</p>
          <div className={styles.priceAndButton}>
            <h4 className={styles.price}>{formatRp(price)}</h4>
            {availability ? (
              <ProductButton
                id={id}
                price={price}
                cart={cart}
                setCart={setCart}
              />
            ) : (
              <span className={styles.empty}>Kosong</span>
            )}
          </div>
          <hr className={styles.divider} />
        </div>
      </div>
    </>
  );
};

export default Product;
